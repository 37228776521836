import { isDiskSource, MimeType, Source } from '@outmind/types';
import React from 'react';

import { CloseIcon, IconButton } from '../../../material';
import { Actions, useDispatch, useSelector } from '../../../store';
import * as logoHelper from '../../../util';
import { SanitizedHtmlTypography, SourceLogo } from '../..';
import { useStyles } from '../styles';
import { OpenInSourceButton } from './OpenInSourceButton';

export const DocumentHeaderPreview: React.FC<DocumentHeaderPreviewProps> = ({
  accessLink,
  title,
  mimeType,
  source,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const previewInfos = useSelector((s) => s.previews.infos);

  if (!previewInfos) return null;

  return (
    <div className={classes.previewHeader}>
      <div className={classes.fileHeaderContent}>
        <div className={classes.headerContentContainer}>
          {mimeType ? logoHelper.getFileLogo(mimeType, classes.resultElementFileType) : null}
          <SourceLogo className={classes.connectorLogo} source={source} />
          <SanitizedHtmlTypography
            className={classes.title}
            href={accessLink}
            html={title}
            isLocalFile={isDiskSource(source)}
            variant="h6"
          />
          <OpenInSourceButton accessLink={accessLink} isLocalFile={isDiskSource(source)} />
        </div>
      </div>
      <IconButton
        aria-label="closeicon"
        classes={{ root: classes.closeIcon }}
        onClick={() => dispatch(Actions.closePreview())}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

interface DocumentHeaderPreviewProps {
  accessLink?: string;
  mimeType?: MimeType;
  source: Source;
  title: string;
}
