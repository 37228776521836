import { isDiskSource, LocalSources, Source } from '@outmind/types';
import React, { useEffect, useState } from 'react';
import useRouter from 'use-react-router';

import { useOrganisationSources, useStartSync } from '../../hooks';
import { Grid } from '../../material';
import { Actions, useDispatch, useSelector } from '../../store';
import { AddSourceDialog } from '../AddSource';
import { PreconfiguredSourcesDialog } from '../AddSource/PreconfiguredSourcesDialog';
import { DisplayOnComputerOnly } from '../DisplayOnComputerOnly';
import { DownloadAppDialog } from '../Document/DownloadAppDialog';
import { DocumentsList } from '../DocumentsList';
import { getSkipDownloadDesktopView } from '../DownloadDesktopAppView';
import { FileNotFoundDialog } from '../FileNotFoundDialog';
import { Filters } from '../Filters';
import { Intercom } from '../Intercom';
import { AppBar } from '../NavBar';
import {
  ConfigureLocalConnectorDialog,
  ConfigureSourceDialog,
  ConfigureSourcesDialog,
} from '../SourcesConfigurationDialog';
import { useStyles } from './styles';

export const MainViewBase: React.FC<MainViewBaseProps> = ({
  actionSource,
  classes,
  connectorId,
  goToDownloadDesktop,
}) => {
  const dispatch = useDispatch();

  const [syncComponents, setSyncComponents] = useState<JSX.Element[]>([]);
  const [showSyncComponents, setShowSyncComponents] = useState<boolean>(false);
  useEffect(() => {
    if (!syncComponents.length) return setShowSyncComponents(false);
  }, [syncComponents.length]);

  const isDesktopAppDialogOpen = useSelector((state) => state.desktopAppDialog.isOpened);
  const isFileNotFoundDialogOpen = useSelector(
    (state) => state.desktopAppDialog.fileNotFoundIsOpened,
  );
  const shouldShowPreconfiguredSourcesDialog = useSelector(
    (state) => state.preconfiguredSources.shouldShowPreconfiguredSourcesDialog,
  );

  const {
    data: organisationSources = { connectedSources: [], sourcesToBeConnected: [] },
    isLoading,
  } = useOrganisationSources();

  // There is absolutly no reason to have this set always true by default. We should only have ONE AddSourceDialog and manage it's opening from here
  const [onboardingMode, setOnboardingMode] = useState(
    !organisationSources.sourcesToBeConnected.length ||
      !organisationSources.connectedSources.length,
  );
  useEffect(() => {
    setOnboardingMode(organisationSources.sourcesToBeConnected.length > 0);
  }, [organisationSources.sourcesToBeConnected.length]);

  // Should we open a modal
  const [modalsOpen, setModalsOpen] = useState(!!shouldShowPreconfiguredSourcesDialog);
  // Open pre-conf modal (the small one)
  const [preconfOpen, setPreconfOpen] = useState(!!shouldShowPreconfiguredSourcesDialog);
  // Open add source modal (the big one)
  const [addSourceOpen, setAddSourceOpen] = useState(false);

  useEffect(() => {
    if (!modalsOpen) return;
    setModalsOpen(false);

    // Open the preconf modal - close the add source
    if (organisationSources.sourcesToBeConnected.length) {
      setAddSourceOpen(false);
      setShowSyncComponents(true);
      return setPreconfOpen(true);
    }

    // Open the add source modal - close the preconf
    setPreconfOpen(false);
    setShowSyncComponents(true);
    return setAddSourceOpen(true);
  }, [isLoading, modalsOpen, organisationSources.sourcesToBeConnected.length]);

  const { mutate: startSync } = useStartSync();

  const { history } = useRouter();

  useEffect(() => {
    // We only want onboarding on first load.
    setOnboardingMode(false);

    if (typeof actionSource === 'string') {
      if (actionSource === 'o365' || actionSource === 'gsuite') {
        const connectorIds = (connectorId as string).split(',').map((sourceId) => {
          const [source, id] = sourceId.split(':');
          return {
            id,
            source: source as Source,
          };
        });
        setSyncComponents([<ConfigureSourcesDialog key="connectors" connectors={connectorIds} />]);
      } else if (isDiskSource(actionSource)) {
        setSyncComponents([
          <ConfigureLocalConnectorDialog
            key={actionSource}
            source={actionSource as LocalSources}
          />,
        ]);
      } else if (actionSource === Source.Notion) {
        startSync({
          connectorId: connectorId as string,
          source: actionSource as Source,
        });
        history.push('/');
      } else {
        const connector = {
          id: connectorId as string,
          source: actionSource as Source,
        };
        setSyncComponents([<ConfigureSourceDialog key={connector.id} connector={connector} />]);
      }
      setModalsOpen(true);
    } else {
      setSyncComponents([]);
    }
  }, [actionSource, connectorId, history, startSync]);

  const goToDownloadDesktopView = !getSkipDownloadDesktopView();
  if (goToDownloadDesktopView) {
    goToDownloadDesktop();
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <AppBar withSearchBar />
      </Grid>
      <DisplayOnComputerOnly>
        <Grid className={classes.leftColumnContainer} item lg={2} md={3}>
          <div className={classes.leftColumnContainerInner}>
            <Filters />
          </div>
        </Grid>
      </DisplayOnComputerOnly>
      <Grid className={classes.mainColumnContainer} item lg={10} md={9}>
        <DocumentsList />
        <DownloadAppDialog
          close={() => dispatch(Actions.closeAppDialog())}
          openDialog={isDesktopAppDialogOpen}
        />
        <FileNotFoundDialog
          close={() => dispatch(Actions.closeFileNotFoundDialog())}
          openDialog={isFileNotFoundDialogOpen}
        />
      </Grid>
      <PreconfiguredSourcesDialog onClose={() => setPreconfOpen(false)} open={preconfOpen} />
      <AddSourceDialog
        close={() => setAddSourceOpen(false)}
        onboardingMode={onboardingMode}
        open={addSourceOpen}
      />
      <Intercom />
      {
        // Help to manage the order of modal opening with useEffect callback
        showSyncComponents ? syncComponents : null
      }
    </Grid>
  );
};

/**
 * Describes the props of the `MainViewBase` component
 */
interface MainViewBaseProps {
  actionSource?: string;
  classes: ReturnType<typeof useStyles>;
  connectorId?: string;
  goToDownloadDesktop: () => void;
}
