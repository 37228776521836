import { ClientConnectorRoot, Source } from '@outmind/types';
import React, { memo, useCallback, useEffect, useState } from 'react';

import { useConnectorRoots, useTranslations } from '../../../hooks';
import { Checkbox, List, ListItem, ListItemIcon, ListItemText, Skeleton } from '../../../material';
import { useStyles } from '../styles';
import { getDeepestflatRoots } from '../util';
import { SelectRootsTreeView } from './SelectRootsTreeView';

const SelectRootsNP: React.FC<SelectRootsProps> = ({
  disabled,
  connectorId,
  setRoots,
  setSelectedRootsIds,
  source,
}) => {
  const classes = useStyles();

  const { t } = useTranslations();

  const [selectedRoots, setInternalSelectedRoots] = useState<Record<string, boolean>>({});
  const [flatRoots, setFlatRoots] = useState<Record<string, ClientConnectorRoot>>({});

  useEffect(() => {
    if (Object.keys(selectedRoots).length) {
      setSelectedRootsIds(
        Object.keys(selectedRoots)
          .filter((rootId) => selectedRoots[rootId] !== flatRoots[rootId]?.isSynced)
          .reduce((_delta, id) => ({ ..._delta, [id]: selectedRoots[id] }), {}),
      );
    }
  }, [flatRoots, selectedRoots, setSelectedRootsIds]);

  const setSelectedRoots = useCallback((delta: Record<string, boolean>): void => {
    setInternalSelectedRoots((selectedRootIds) =>
      Object.entries(delta).reduce(
        (acc, [rootId, bool]) => ({ ...acc, [rootId]: bool }),
        selectedRootIds,
      ),
    );
  }, []);

  const addRootsToRootsToSync = useCallback(
    (rootsToSync: Record<string, boolean>): void =>
      setSelectedRoots(
        Object.keys(rootsToSync).reduce(
          (_rootsToSync, rootToSync) => ({
            ..._rootsToSync,
            [rootToSync]: true,
          }),
          {},
        ),
      ),
    [setSelectedRoots],
  );

  const removeRootsFromRootsToSync = useCallback(
    (rootsNotToSync: Record<string, boolean>): void =>
      setSelectedRoots(
        Object.keys(rootsNotToSync).reduce(
          (_rootsNotToSync, rootNotToSync) => ({
            ..._rootsNotToSync,
            [rootNotToSync]: false,
          }),
          {},
        ),
      ),
    [setSelectedRoots],
  );

  const { data: roots = [], isLoading: getRootsLoading } = useConnectorRoots(connectorId, source);

  useEffect((): void => {
    setFlatRoots(
      roots.reduce((_flatRoots, root) => ({ ..._flatRoots, ...getDeepestflatRoots(root) }), {}),
    );
  }, [roots]);

  useEffect((): void => {
    setRoots(!Object.keys(flatRoots).length);
  }, [flatRoots, setRoots]);

  const rootsCheckboxesSkeletons = getRootsLoading
    ? Array(6)
        .fill(undefined)
        .map((_, i) => (
          <ListItem key={i} button dense>
            <ListItemIcon className={classes.rootCheckbox}>
              <Checkbox checked disabled edge="start" tabIndex={-1} />
            </ListItemIcon>
            <Skeleton height={20} variant="text" width={150} />
          </ListItem>
        ))
    : null;

  const noRootToSyncHelper =
    !getRootsLoading && !Object.keys(roots).length ? (
      <ListItem>
        <ListItemText
          className={classes.noRootToSyncHelperText}
          primary={t('no_syncable_elements')}
        />
      </ListItem>
    ) : null;

  return (
    <List className={classes.rootsCheckboxes} data-test="roots:selection">
      {rootsCheckboxesSkeletons}
      {noRootToSyncHelper}
      <SelectRootsTreeView
        addRootsToRootsToSync={addRootsToRootsToSync}
        disabled={disabled}
        removeRootsFromRootsToSync={removeRootsFromRootsToSync}
        roots={roots}
        selectedRootsIds={selectedRoots}
      />
    </List>
  );
};

export const SelectRoots = memo(SelectRootsNP);

interface SelectRootsProps {
  connectorId: string;
  disabled?: boolean;
  setRoots: (roots: boolean) => void;
  setSelectedRootsIds: (roots: Record<string, boolean>) => void;
  source: Source;
}
