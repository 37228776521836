import { ExtendedSources, GSuiteSources, Source, sources } from '@outmind/types';
import clsn from 'classnames';
import React, { memo } from 'react';

import { useGetAuthUrl, useLinkHandler, useRuntimeEnv } from '../../../hooks';
import { ButtonBase, Card, CardContent, Grid, Typography } from '../../../material';
import { SourceLogo } from '../../SourceLogo';
import { useStyles } from './styles';

const AddOAuthSourceButtonNP: React.FC<AddOAuthSourceButtonProps> = ({
  beta,
  disabled,
  openAddSourceConfirmationDialog,
  source,
}) => {
  const classes = useStyles();

  const { data: authUrl, isFetched } = useGetAuthUrl(source);

  const { inElectron } = useRuntimeEnv();
  const openSourceAuthWindow = useLinkHandler(authUrl, { targetBlank: inElectron });

  const handleOnClick =
    source === 'gsuite' || GSuiteSources.includes(source as Source)
      ? openAddSourceConfirmationDialog
      : openSourceAuthWindow;

  return (
    <Grid data-test={`add:source:btn:${source}:${isFetched}`} item sm={3}>
      <Card className={classes.sourceCard}>
        <ButtonBase
          classes={{ disabled: classes.sourceButtonDisabled }}
          className={classes.sourceButton}
          disabled={disabled}
          onClick={handleOnClick}
        >
          <CardContent
            classes={{ root: classes.cardContentRoot }}
            className={clsn({
              [classes.sourceButtonBeta]: beta,
            })}
          >
            <div className={classes.sourceLogoContainer}>
              <SourceLogo className={classes.sourceLogo} source={source} />
            </div>
            <Typography className={classes.sourceName} variant="subtitle2">
              {sources[source].name}
            </Typography>
          </CardContent>
        </ButtonBase>
      </Card>
    </Grid>
  );
};

interface AddOAuthSourceButtonProps {
  beta: boolean;
  disabled?: boolean;
  openAddSourceConfirmationDialog: () => void;
  source: ExtendedSources;
}

export const AddOAuthSourceButton = memo(AddOAuthSourceButtonNP);
