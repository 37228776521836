import { ArrowSync } from '@styled-icons/fluentui-system-regular/ArrowSync';
import { CloudDownload } from '@styled-icons/fluentui-system-regular/CloudDownload';
import React, { useEffect, useState } from 'react';

import { useGetUpdateState, useRuntimeEnv, useTranslations } from '../../../hooks';
import { ListItemIcon, ListItemText, MenuItem, Typography } from '../../../material';
import { useStyles } from '../styles';

export const UpdateButton: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslations();

  const { inElectron } = useRuntimeEnv();

  const { data: updateState, refetch } = useGetUpdateState();

  if (!inElectron || !window.electron) return null;

  const [refreshingUpdateStateInterval, setRefreshingUpdateStateInterval] =
    useState<ReturnType<typeof setInterval>>();
  const [checkingForUpdate, setCheckingForUpdate] = useState(false);
  const [checkingForUpdateTriggered, setCheckingForUpdateTriggered] = useState(false);
  const [updateIsDownloading, setUpdateIsDownloading] = useState(false);

  const checkForUpdates = async (): Promise<void> => {
    setCheckingForUpdate(true);
    setCheckingForUpdateTriggered(true);
    const updateAvailable = await window.electron!.checkForUpdates();
    setTimeout(() => {
      setCheckingForUpdate(false);
      setUpdateIsDownloading(updateAvailable);
    }, 2000);
  };

  useEffect(() => {
    if (updateIsDownloading) {
      const intervalId = setInterval(async () => {
        await refetch();
      }, 2000);
      setRefreshingUpdateStateInterval(intervalId);
    } else if (refreshingUpdateStateInterval) {
      clearInterval(refreshingUpdateStateInterval);
    }
  }, [updateIsDownloading]);

  useEffect(() => {
    if (updateState?.updateAvailable) setUpdateIsDownloading(false);
  }, [updateState?.updateAvailable]);

  const checkForUpdateText = (): string => {
    if (updateIsDownloading) return t('downloading_update');

    if (checkingForUpdateTriggered && !checkingForUpdate) return t('no_update_available');

    if (checkingForUpdate) return t('checking_for_updates');

    return t('check_for_updates');
  };

  if (updateState?.updateAvailable) {
    return (
      <MenuItem
        className={classes.styledMenuItemRoot}
        onClick={() => {
          if (inElectron) window.electron?.restartAndUpdate();
        }}
      >
        <ListItemIcon>
          <CloudDownload size="20" />
        </ListItemIcon>
        <ListItemText
          className={classes.itemText}
          disableTypography
          primary={t('update_latest_version')}
        />
      </MenuItem>
    );
  }

  return (
    <MenuItem
      className={classes.styledMenuItemRoot}
      disabled={checkingForUpdate || updateIsDownloading}
      onClick={checkForUpdates}
      style={{ alignItems: 'start', flexDirection: 'column' }}
    >
      <div style={{ alignItems: 'center', display: 'flex' }}>
        <ListItemIcon>
          <ArrowSync
            className={checkingForUpdate || updateIsDownloading ? classes.spiningIcon : undefined}
            size="20"
          />
        </ListItemIcon>

        <ListItemText
          className={classes.itemText}
          disableTypography
          primary={checkForUpdateText()}
        />
      </div>
      <Typography className={classes.secondaryListItemText}>
        {`${t('current')} ${updateState?.currentVersion}`}
      </Typography>
    </MenuItem>
  );
};
