import { ClientConnectorRoot, ConnectorRootType } from '@outmind/types';
import React, { useCallback } from 'react';

import { useTranslations } from '../../../hooks';
import { TranslationKey } from '../../../locales/types';
import {
  BusinessIcon,
  Checkbox,
  DashboardIcon as BoardIcon,
  FolderIcon,
  InboxIcon,
  MessageIcon,
  MissionIcon,
  SvgIconProps,
  TeamIcon,
  TreeItem,
  Typography,
} from '../../../material';
import { useStyles } from '../styles';
import { getDeepestRootsIds } from '../util';

export const SelectRootsTreeItem: React.FC<SelectRootsTreeItemProps> = ({
  addRootsToRootsToSync,
  disabled,
  removeRootsFromRootsToSync,
  root,
  selectedRootsIds,
}) => {
  const classes = useStyles();
  const { t } = useTranslations();

  const rootNameTranslationKey: string | undefined =
    root.name?.match(/should_translate::(.+)/)?.[1];

  const rootIsChecked = useCallback(
    (_root: ClientConnectorRoot): boolean => {
      if (_root.subRoots) {
        return _root.subRoots.reduce<boolean>(
          (bool, subRoot) => bool && rootIsChecked(subRoot),
          true,
        );
      }
      return selectedRootsIds[_root.id] ?? _root.isSynced ?? true;
    },
    [selectedRootsIds],
  );

  const rootIsIndeterminate = useCallback(
    (_root: ClientConnectorRoot): boolean => {
      if (_root.subRoots) {
        return (
          !rootIsChecked(_root) &&
          _root.subRoots.reduce<boolean>(
            (bool, subRoot) => bool || rootIsIndeterminate(subRoot) || rootIsChecked(subRoot),
            false,
          )
        );
      }
      return false;
    },
    [rootIsChecked],
  );

  if (!root.type && !root.name) return null;

  return (
    <TreeItem
      classes={{
        content: classes.treeItemContent,
        expanded: classes.treeItemExpanded,
        group: classes.treeItemGroup,
        iconContainer: classes.treeItemIconContainer,
        label: classes.treeItemLabel,
        root: classes.treeItemRoot,
        selected: classes.treeItemSelected,
      }}
      label={
        <div className={classes.treeItemLabelRoot} data-test={`root:treeitem:${root.name}`}>
          <Checkbox
            checked={rootIsChecked(root)}
            disabled={disabled}
            indeterminate={rootIsIndeterminate(root)}
            onChange={(e) => {
              const allSubRootIds = getDeepestRootsIds(root);
              if (e.target.checked) addRootsToRootsToSync(allSubRootIds);
              else removeRootsFromRootsToSync(allSubRootIds);
            }}
            onClick={(e) => e.stopPropagation()}
          />
          {rootIconMap[root.type]({ className: classes.treeItemLabelIcon })}
          <Typography className={classes.treeItemLabelText}>
            {rootNameTranslationKey ? t(rootNameTranslationKey as TranslationKey) : root.name}
          </Typography>
        </div>
      }
      nodeId={root.id}
    >
      {root.subRoots?.map((subRoot) => (
        <SelectRootsTreeItem
          key={subRoot.id}
          addRootsToRootsToSync={addRootsToRootsToSync}
          disabled={disabled}
          removeRootsFromRootsToSync={removeRootsFromRootsToSync}
          root={subRoot}
          selectedRootsIds={selectedRootsIds}
        />
      ))}
    </TreeItem>
  );
};

/**
 * Maps a `ConnectorRootType` to the related displayed icon
 */
const rootIconMap: Record<ConnectorRootType, (props: SvgIconProps) => JSX.Element> = {
  board: (props: SvgIconProps) => <BoardIcon {...props} />,
  chat: (props: SvgIconProps) => <MessageIcon {...props} />,
  crm: (props: SvgIconProps) => <BusinessIcon {...props} />,
  drive: (props: SvgIconProps) => <FolderIcon {...props} />,
  mailBox: (props: SvgIconProps) => <InboxIcon {...props} />,
  mission: (props: SvgIconProps) => <MissionIcon {...props} />,
  site: (props: SvgIconProps) => <FolderIcon {...props} />,
  team: (props: SvgIconProps) => <TeamIcon {...props} />,
};

interface SelectRootsTreeItemProps {
  addRootsToRootsToSync: (rootsToSync: Record<string, boolean>) => void;
  disabled?: boolean;
  removeRootsFromRootsToSync: (rootsNotToSync: Record<string, boolean>) => void;
  root: ClientConnectorRoot;
  selectedRootsIds: Record<string, boolean>;
}
